<template>
  <div class="elv-report-reconciliation-detail-header">
    <el-tabs
      v-model="tabActiveName"
      type="border-card"
      class="elv-report-reconciliation-detail-tab"
      @tab-click="onChangeTab"
    >
      <el-tab-pane name="entity-reconciliation-match-set-detail" class="elv-report-detail-pane">
        <template #label>
          <p>
            {{ t('report.matchSetDetail') }}
          </p>
        </template>
      </el-tab-pane>
      <el-tab-pane name="entity-reconciliation-matched" class="elv-report-detail-pane">
        <template #label>
          <p>
            {{ t('report.matches') }}
          </p>
        </template>
      </el-tab-pane>
      <el-tab-pane name="entity-reconciliation-unmatched" class="elv-report-detail-pane">
        <template #label>
          <p>
            {{ t('report.unmatched') }}
          </p>
        </template>
      </el-tab-pane>
      <el-tab-pane name="entity-reconciliation-history" class="elv-report-detail-pane">
        <template #label>
          <p>
            {{ t('title.matchSetHistory') }}
          </p>
        </template>
      </el-tab-pane>
    </el-tabs>

    <div class="elv-report-reconciliation-detail-header-right">
      <elv-export-button
        :params="{
          type: 'RECONCILIATION',
          extra: { type: 'MATCH_SET', reconciliationSetId: reconciliationSetId }
        }"
        :text="t('button.exportMatchSet')"
        :noPermission="false"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import ElvExportButton from '@/components/Base/ElvExportButton.vue'

const { t } = useI18n()
const route = useRoute()
const router = useRouter()

const tabActiveName = ref('entity-reconciliation-match-set-detail')

const reconciliationSetId = computed(() => {
  return String(route.params?.reconciliationSetId)
})

const onChangeTab = (tab: any) => {
  router.push({
    name: tab.props.name
  })
}

onMounted(() => {
  tabActiveName.value = String(route.name)
})
</script>

<style lang="scss" scoped>
.elv-report-reconciliation-detail-header {
  position: relative;
}

.elv-report-reconciliation-detail-header-right {
  height: 34px;
  display: flex;
  align-items: center;
  position: absolute;
  right: 30px;
  top: 0;
}
</style>
